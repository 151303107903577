<template>
  <div class="top">
    <div class="topTop">
      客服下班后提醒
      <el-switch @change="welcomeChange" style="display: block" v-model="offwork"> </el-switch>
    </div>
    <div class="topBot">
      转人工后，买家在下班时间发来消息，且所有客服的电脑和手机端均不在线时，会发送这个回复
    </div>
  </div>
  <div class="showTime" v-show="!workTimeFlag">
    <div class="showBox">
      <div style="line-height: 28px">上班时间：</div>
      <div>
        <template v-for="(item, index) in workTime" :key="index">
          <div style="line-height: 28px">
            {{ item.timeArr[0] }} - {{ item.timeArr[1] }}(<template
              v-for="(item1, index1) in item.days"
              :key="index1"
            >
              {{
                item1 == '0'
                  ? '周一'
                  : item1 == '1'
                  ? '周二'
                  : item1 == '2'
                  ? '周三'
                  : item1 == '3'
                  ? '周四'
                  : item1 == '4'
                  ? '周五'
                  : item1 == '5'
                  ? '周六'
                  : '周日'
              }}
              {{ index1 >= item.days.length - 1 ? '' : '、' }}</template
            >)
          </div>
        </template>
      </div>
    </div>
    <div class="timeUpdateBox">
      <span class="textBtn" @click="updateTimeArr">编辑</span>
    </div>
  </div>
  <div class="addWorkTime" v-show="workTimeFlag">
    <div class="addBox">
      <div style="line-height: 32px; flex-shrink: 0">上班时间：</div>
      <div>
        <template v-for="(item, index) in cloneTime" :key="index">
          <div class="timeItem">
            <div>
              <el-time-picker
                is-range
                v-model="item.timeArr"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                size="small"
                value-format="HH:mm"
                format="HH:mm"
              >
              </el-time-picker>
            </div>
            <div>
              上班周期：
              <el-checkbox-group v-model="item.days">
                <el-checkbox label="0">周一</el-checkbox>
                <el-checkbox label="1">周二</el-checkbox>
                <el-checkbox label="2">周三</el-checkbox>
                <el-checkbox label="3">周四</el-checkbox>
                <el-checkbox label="4">周五</el-checkbox>
                <el-checkbox label="5">周六</el-checkbox>
                <el-checkbox label="6">周日</el-checkbox>
              </el-checkbox-group>
            </div>
            <div
              @click="delTime(index)"
              style="margin-left: 10px"
              class="textBtn"
              v-if="index != 0"
            >
              删除
            </div>
          </div>
        </template>
      </div>
    </div>
    <div style="padding-left: 70px; margin-bottom: 12px">
      <span class="textBtn" @click="addTime">新增上班时段</span>
    </div>
    <div class="resultBox">
      <div class="custBtnPri" @click="successAddTime">保存</div>
      <div class="custBtn" @click="cancelAddTime">取消</div>
    </div>
  </div>
  <div class="mid">
    <div class="yuan"></div>
    回复内容(若都勾选则自动回复文字消息+常见问题)
  </div>
  <div class="footer">
    <Phone type="offwork" :obj="welcome"></Phone>
    <div class="footerRight">
      <div class="sendTxtMsg">
        <el-checkbox :disabled="!offwork" v-model="welcome.sendTxtMsg.showflag"
          >发送文字消息</el-checkbox
        >
        <div class="content">
          <div class="leftTitle">回复内容:</div>
          <div class="rightContent">
            <div class="txt" v-if="!updateFlag && welcome.sendTxtMsg.content">
              {{ welcome.sendTxtMsg.content }}
            </div>
            <ChatInputBox
              v-if="updateFlag"
              :chatData="chatData"
              @getChatData="getChatData"
            ></ChatInputBox>
            <div v-if="!updateFlag" class="update" @click="updateSendMsg">编辑</div>
            <div v-else class="updateRes">
              <div @click="updateSuccess" class="custBtnPri">保存</div>
              <div @click="updateFlag = false" class="custBtn">取消</div>
            </div>
          </div>
        </div>
      </div>
      <div class="sendCommonProblem">
        <el-checkbox :disabled="!offwork" v-model="welcome.sendCommonProblem.showflag"
          >发送常见问题</el-checkbox
        >
        <div class="problemList">
          <div class="problemListLeft">问题列表:</div>
          <div class="problemListRight">
            <template v-for="(item, index) in welcome.sendCommonProblem.list" :key="index">
              <div>{{ item.asking }}</div>
            </template>
            <div class="txtTips">
              回复内容等详细设置请前往常见问题页设置设置常见问题<span
                @click="$router.push('/autoReply/serviceReply/faq')"
                style="margin-left: 10px"
                class="textBtn"
                >设置常见问题</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch, inject } from 'vue'
import { post } from '../../../util/axios'
import { api } from '../../../util/api'
import Phone from '@/components/phone.vue'
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import ChatInputBox from '@/components/chatInputBox'
export default {
  components: {
    Phone,
    ChatInputBox
  },
  setup() {
    const offwork = ref(true)
    let replyInfo = inject('replyInfo')
    offwork.value = replyInfo.value.service_reminder_after_work ? true : false
    const welcomeChange = (val) => {
      replyInfo.value.service_reminder_after_work = val ? 1 : 0
      post(api.setAutomaticReply, {
        service_reminder_after_work: val ? 1 : 0
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success('修改成功')
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    const commonProblemList = inject('commonProblemList')
    const welcome = reactive({
      sendTxtMsg: {
        showflag: replyInfo.value.service_reminder_after_work_data.text_open == '1' ? true : false,
        content: replyInfo.value.service_reminder_after_work_data.text_value
      },
      sendCommonProblem: {
        showflag:
          replyInfo.value.service_reminder_after_work_data.common_problem == '1' ? true : false,
        list: commonProblemList.value
      }
    })

    let updateFlag = ref(false)
    const chatData = ref('')
    const getChatData = (val) => {
      chatData.value = val
    }
    // 点击修改欢迎语
    const updateSendMsg = () => {
      chatData.value = welcome.sendTxtMsg.content
      updateFlag.value = true
    }
    // 完成修改
    const updateSuccess = () => {
      welcome.sendTxtMsg.content = chatData.value
      if (!welcome.sendTxtMsg.content) {
        welcome.sendTxtMsg.showflag = false
      }
      updateFlag.value = false
    }
    const workTime = ref([])
    const cloneTime = ref([])
    const workTimeFlag = ref(false)
    if (replyInfo.value.service_reminder_after_work_data.on_time.length > 0) {
      replyInfo.value.service_reminder_after_work_data.on_time.map((item) => {
        workTime.value.push({
          timeArr: [item.start_time, item.end_time],
          days: item.week.split(',')
        })
      })
    } else {
      workTime.value.push({ timeArr: ['08:00', '17:00'], days: ['0', '1', '2', '3', '4'] })
    }

    let cloneList = reactive({
      a: JSON.parse(JSON.stringify(welcome.sendCommonProblem.list))
    })
    watch(
      welcome,
      (val) => {
        if (val.sendCommonProblem.list.toString() == cloneList.a.toString()) {
          let on_time = []
          workTime.value.map((item) => {
            on_time.push({
              start_time: item.timeArr[0],
              end_time: item.timeArr[1],
              week: item.days.toString()
            })
          })
          post(api.setAutomaticReply, {
            service_reminder_after_work_json: {
              text_open: val.sendTxtMsg.showflag ? 1 : 0,
              text_value: val.sendTxtMsg.content,
              common_problem: val.sendCommonProblem.showflag ? 1 : 0,
              on_time
            }
          }).then((res) => {
            if (res.code == 0) {
              ElMessage.success('修改成功')
            } else {
              ElMessage.error(`${res.msg}`)
            }
          })
        }
        cloneList.a = JSON.parse(JSON.stringify(val.sendCommonProblem.list))
      },
      {
        deep: true
      }
    )
    const updateTimeArr = () => {
      cloneTime.value = _.cloneDeep(workTime.value)
      workTimeFlag.value = true
    }
    const addTime = () => {
      if (cloneTime.value.length >= 5) {
        ElMessage.warning('最多可添加五条上班时间')
        return
      }
      cloneTime.value.push({ timeArr: ['08:00', '17:00'], days: ['0', '1', '2', '3', '4'] })
    }
    const successAddTime = () => {
      workTime.value = cloneTime.value
      workTimeFlag.value = false
      let on_time = []
      workTime.value.map((item) => {
        on_time.push({
          start_time: item.timeArr[0],
          end_time: item.timeArr[1],
          week: item.days.toString()
        })
      })
      post(api.setAutomaticReply, {
        service_reminder_after_work_json: {
          text_open: welcome.sendTxtMsg.showflag ? 1 : 0,
          text_value: welcome.sendTxtMsg.content,
          common_problem: welcome.sendCommonProblem.showflag ? 1 : 0,
          on_time
        }
      }).then((res) => {
        if (res.code == 0) {
          ElMessage.success('修改成功')
        } else {
          ElMessage.error(`${res.msg}`)
        }
      })
    }
    const cancelAddTime = () => {
      cloneTime.value = workTime.value
      workTimeFlag.value = false
    }
    const delTime = (index) => {
      cloneTime.value.splice(index, 1)
    }
    return {
      offwork,
      welcome,
      chatData,
      getChatData,
      updateSuccess,
      updateSendMsg,
      workTime,
      cloneTime,
      workTimeFlag,
      updateTimeArr,
      addTime,
      successAddTime,
      cancelAddTime,
      welcomeChange,
      delTime,
      updateFlag
    }
  }
}
</script>

<style lang="less" scoped>
.top {
  padding: 20px;
  background-color: #f7f8fa;
  .topTop {
    display: flex;
    align-items: center;
    font-size: 15px;
    .el-switch {
      margin-left: 10px;
    }
  }
  .topBot {
    margin-top: 16px;
    font-size: 14px;
    color: #999;
  }
}
.showTime {
  margin: 20px 0 10px;
  .showBox {
    display: flex;
    align-items: flex-start;
  }
  .timeUpdateBox {
    margin-top: 10px;
  }
}
.addWorkTime {
  margin: 20px 0 10px;
  .addBox {
    display: flex;
    align-items: flex-start;
  }
  .resultBox {
    display: flex;
    & > div:first-child {
      margin-left: 70px;
      margin-right: 10px;
    }
  }
  .timeItem {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    & > div {
      display: flex;
      align-items: center;
    }
    & > div:first-child {
      margin-right: 10px;
    }
    :deep(.el-date-editor) {
      width: 200px !important;
    }
  }
}
.mid {
  margin-top: 10px;
  padding: 15px 20px;
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  .yuan {
    margin-right: 5px;
    width: 6px;
    height: 6px;
    background: #409eff;
    border-radius: 50%;
  }
}
.footer {
  margin-top: 23px;
  margin-bottom: 80px;
  display: flex;
  align-items: flex-start;
  .footerRight {
    margin-left: 34px;
    width: calc(100% - 409px);
    :deep(.el-checkbox) {
      :depp(.el-checkbox__label) {
        color: #333 !important;
      }
    }
    .content {
      margin-top: 17px;
      display: flex;
      .leftTitle {
        margin-left: 20px;
        margin-right: 10px;
        flex-shrink: 0;
        line-height: 1.5;
      }
      .txt {
        line-height: 1.5;
        margin-bottom: 14px;
        white-space: pre-wrap;
      }
      .update {
        color: #409eff;
        user-select: none;
        line-height: 1.5;
        cursor: pointer;
      }
      .updateRes {
        display: flex;
        margin-top: 15px;
        & > div {
          margin-right: 10px;
        }
      }
      .updateTxt {
        .el-textarea {
          width: 500px;
          overflow: hidden;
          overflow-y: auto;
          :deep(.el-textarea__inner) {
            padding: 10px 0 10px 20px;
            resize: none;
            height: 110px;
            border-radius: 0;
            &:focus {
              border-color: #dcdfe6 !important;
            }
          }
        }
        .expression {
          padding: 0 10px;
          width: 500px;
          height: 33px;
          border: 1px solid #dcdfe6;
          border-width: 0 1px 1px 1px;
          display: flex;
          align-items: center;
          i {
            font-size: 24px;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }
}
.emojisList {
  width: 100%;
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  div {
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
  }
  span {
    display: block;
    width: 49px;
    height: 40px;
    cursor: pointer;
    margin: 0 5px 5px 0;
    font-size: 30px;
    user-select: none;
    text-align: center;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
}
.el-popover .classStyle {
  padding-bottom: 12px;
  span {
    display: inline-block;
    padding: 5px 4px;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
  }
}
.sendCommonProblem {
  margin-top: 20px;
  .problemList {
    display: flex;
    margin-left: 24px;
    margin-top: 17px;
    .problemListRight {
      margin-left: 10px;
      & > div {
        margin-bottom: 12px;
      }
    }
  }
}
.sendGoods {
  .txtTips {
    margin-top: 10px;
    line-height: 1.5;
    margin-left: 24px;
    p {
      font-size: 12px !important;
    }
  }
}
.wxCode {
  margin-top: 20px;
  .txtTips {
    margin-top: 10px;
    line-height: 1.5;
    margin-left: 24px;
  }
}
</style>
